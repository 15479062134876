<template>
    <div style="">
        <a-card title="水质查询" :bordered="false" style="">
            <div class="table-page-search-wrapper">
                <a-form :form="form" layout="inline">
                    <a-row :gutter="[24, 0]">
                        <a-col :xxl="8" :sm="24">
                            <a-form-item label="选择水厂：">
                                <a-cascader
                                        v-if="cascaderDefault.length > 0"
                                        :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                                        :options="tree"
                                        placeholder=""
                                        :allowClear="false"
                                        :defaultValue="cascaderDefault"
                                        @change="onCascaderChange"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
                            <a-form-item label="指标类型：">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.zhibiao"
                                >
                                    <a-select-option v-for="item in zhibiaos" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
                            <a-form-item no-prop label="监测点">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.mid"
                                >
                                    <a-select-option v-for="item in monitors" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
                            <a-form-item no-prop label="监测管井">
                                <a-select
                                        style=""
                                        placeholder="请选择"
                                        v-model="queryParam.groupid"
                                >
                                    <a-select-option v-for="item in groups" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xxl="8" :sm="24">
                            <a-form-item no-prop label="选择日期">
                                <a-range-picker :showTime="queryParam.zhibiao != 2" v-model="range"/>
                            </a-form-item>
                        </a-col>

                        <a-col :xxl="8" :sm="24">
              <span
                      class="table-page-search-submitButtons"
              >
                <a-button type="primary" @click="handleQuery">查询</a-button>
                  <!--<a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>-->
              </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </a-card>
        <a-card title="查询结果" :bordered="false" style="margin-top: 5px;">
            <div slot="extra">
                <a-radio-group v-model="dataType" v-if="queryZhibiao == 2" button-style="solid" >
                    <a-radio-button value="1">
                        单日流量
                    </a-radio-button>
                    <a-radio-button value="2">
                        累计流量
                    </a-radio-button>
                    <a-radio-button value="3">
                        实时数据
                    </a-radio-button>
                </a-radio-group>
                <a-button type="primary" style="margin-left:20px;" @click="exportReport">
                    导出数据
                </a-button>
            </div>
            <a-spin :spinning="confirmLoading">
                <div  style="height:300px;" ref="chart"></div>
                <!--<div v-else style="height:300px;" ref="chart1"></div>-->
            </a-spin>
        </a-card>
        <a-card>
            <a-table
                    row-key="index"
                    :columns="columns"
                    :dataSource="data"
                    :pagination="pagination"
                    :loading="confirmLoading"
                    @change="handleTableChange"
            >
                <a slot="leakPercent" slot-scope="text, record">{{
                    text
                    }}%</a>
                <template slot="index" slot-scope="text, record">
                    <div>{{ text }}</div>
                </template>
                <template slot="time" slot-scope="text, record">
                    <div>{{ record.start }} - {{record.end}}</div>
                </template>
            </a-table>
        </a-card>
    </div>
</template>

<script>
  import moment from 'moment'
  import * as echarts from "echarts";
  import {getAreaParam,monitorList,monitorListByZhibiao,groupList,pipeQuery,pipeQueryExprot,waterfactoryTree,connections} from "@/api/monitor/index";
  import { export_json_to_excel } from '@/excel/Export2Excel'
  const publicColums = [
    {
      dataIndex: "index",
      title: "序号",
      align: "center",
      scopedSlots: { customRender: "index" }
    },
    {
      dataIndex: "mname",
      title: "监测点",
      align: "center"
    },
    {
      dataIndex: "gname",
      title: "监测管井",
      align: "center"
    },
    {
      dataIndex: "time",
      title: "时间",
      align: "center"
    },
    {
      dataIndex: "value",
      title: "水压(MPa)",
      align: "center"
    }
  ];
  export default {
    name: "qualityquery",
    data() {
      return {
        form: this.$form.createForm(this),
        confirmLoading: false,
        columns: publicColums,
        data: [],
        pagination: {
          size: "",
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showLessItems: true,
          pageSizeOptions: ["5", "10", "20", "30", "50"],
          onShowSizeChange: (current, pageSize) => {
            this.pageSize = pageSize;
          },
          showTotal: total => `共${total}条`
        },
        tree: [],
        cascaderDefault: [],
        connections: [],
        fromMonitors: [],
        toMonitors:[],
        queryParam: { pageNum: 1, pageSize: 10, orderBy: '', wfid: undefined, zhibiao: 6, mid: undefined, groupid: 0,timeType: 'hour',start: moment().subtract(4,'days'), end: moment(), dataType: 1},
        years: [2021],
        init: false,
        zhibiaos: [{id:6,name:'PH',title:'PH'},{id:4,name:'浊度',title:'浊度'}],
        monitors: [],
        groups: [],
        range: [moment().subtract(4,'days'),moment()],
        myChart: null,
        myChart1: null,
        queryZhibiao: 2,
        dataType: '1',
        chartOption1: null,
        chartOption2: null,
        chartOption3:null,
        needReloadOption12: false,
        needReloadOption3:false,
      };
    },
    created() {
      let param = getAreaParam();
      waterfactoryTree(param).then(r=>{
        this.tree = [r.data]
        this.cascaderDefault = this.getTreeDefaultValue(this.tree)
        console.log(this.cascaderDefault)
        let wfid = this.cascaderDefault[this.cascaderDefault.length - 1]
        this.queryParam.wfid = wfid

      })
    },
    mounted() {
      this.myChart = echarts.init(this.$refs.chart);
      window.addEventListener('resize', this.resize)
    },
    watch:{
      dataType(val){
        console.log('dataType',val)
        this.queryParam.dataType = parseInt(val)
        this.fetchList()
        // if(val == 1){
        //   this.myChart.setOption(this.chartOption1,true)
        // }else if(val == 2){
        //   this.myChart.setOption(this.chartOption2,true)
        // }else{
        //   this.fetchList()
        // }
        // this.resize()
      },
      'queryParam.wfid' (val){
        console.log('watch queryParam.wfid',val)
        monitorListByZhibiao({wfid:val,zhibiao:this.queryParam.zhibiao}).then(r=>{
          this.monitors = r.data
          this.monitors.unshift({id:0,name:'全部'})
          const len = this.monitors.length
          if(len > 0){
            const mid = this.monitors[0].id
            this.queryParam.mid = mid
          }
        })
      },
      'queryParam.zhibiao' (val){

        monitorListByZhibiao({wfid:this.queryParam.wfid,zhibiao:val}).then(r=>{
          this.monitors = r.data
          this.monitors.unshift({id:0,name:'全部'})
          const len = this.monitors.length
          if(len > 0){
            const mid = this.monitors[0].id
            this.queryParam.mid = mid
            this.queryParam.groupid = 0
          }
        })
      },
      'queryParam.mid' (val){
        if(val == 0){
          this.groups.length = 0
          this.groups.unshift({id:0,name:'全部'})
          this.queryParam.groupid = 0
          if(!this.init){
            this.init = true
            this.fetchList()
          }
        }else{
          groupList({mid:val}).then(r=>{
            this.groups = r.data
            this.groups.unshift({id:0,name:'全部'})
            if(!this.init){
              this.init = true
              this.fetchList()
            }
          })
        }
      },
      'queryParam.timeType'(val){
        switch (val) {
          case 'hour':
            this.queryParam.date = moment()
            break
          case 'day':
            this.queryParam.date = moment()
            break
          case 'month':
            this.queryParam.date = this.years[0]
            break
        }
      }
    },
    methods: {
      resize () {
        if (this.myChart) {
          this.myChart.resize()
        }
        if(this.myChart1){
          this.myChart1.resize()
        }
      },
      getTreeDefaultValue(tree){
        let arr = []
        let first = tree[0]
        arr.push(first.id)
        while (first.children && first.children.length > 0){
          arr.push(first.children[0].id)
          first = first.children[0]
        }
        return arr
      },
      fetchList(){
        this.confirmLoading = true
        const param = Object.assign({}, this.queryParam)
        this.queryZhibiao = param.zhibiao
        if(param.zhibiao != 2){
          this.dataType = '1'
        }
        param.start = moment(this.range[0]).format('YYYY-MM-DD HH:mm:ss')
        param.end = moment(this.range[1]).format('YYYY-MM-DD HH:mm:ss')
        pipeQuery(param).then(r=>{

          const data = r.data
          // const list = data.list
          // const len = list.length
          // for (let i = 0; i < len; i++) {
          //   list[i].index = i + 1
          // }
          const pageDetail = data.pageDetail
          const list = pageDetail.list
          const len = list.length
          const pageNum = pageDetail.pageNum
          const pageSize = pageDetail.pageSize
          for (let i = 0; i < len; i++) {
            list[i].index = (pageNum-1) * pageSize + i + 1
          }
          this.pagination.current = pageNum
          this.pagination.total = pageDetail.total
          this.pagination.pageSize = pageSize

          this.columns[this.columns.length - 1].title = this.getZhibiaoTitle(this.queryParam.zhibiao)
          this.data = list

          if(param.zhibiao == 2){//流量
            const series = data.series
            let xdata = []
            let seriesData = []
            if(param.dataType == 3){
            }else{
              for (let i = 0; i < series.length; i++) {
                xdata.push(series[i].name)
                let d = series[i].data
                let n = 0
                for (let j = 0; j < d.length; j++) {
                  n += d[j]
                }
                seriesData.push(n)
              }
              this.chartOption2 = this.getChartOption2(xdata,seriesData)
            }
          }
          if(param.zhibiao == 2){
            switch (param.dataType) {
              case 1:
                this.chartOption1 = this.getChartOption1(data.xdata,data.series)
                this.myChart.setOption(this.chartOption1,true)
                break
              case 2:
                this.myChart.setOption(this.chartOption2,true)
                break
              case 3:
                this.chartOption3 = this.getChartOption1(data.xdata,data.series)
                this.myChart.setOption(this.chartOption3,true)
                break
            }
          }else{
            this.chartOption1 = this.getChartOption1(data.xdata,data.series)
            this.myChart.setOption(this.chartOption1,true)
          }

          this.confirmLoading = false
        }).catch(err =>{
          console.log(err)
          this.confirmLoading = false
        })
      },
      exportReport() {
        const param = Object.assign({}, this.queryParam)
        param.start = moment(this.range[0]).format('YYYY-MM-DD HH:mm:ss')
        param.end = moment(this.range[1]).format('YYYY-MM-DD HH:mm:ss')
        param.pageNum = 1
        param.pageSize = 1000000
        pipeQuery(param).then(res => {
          let list = res.data.pageDetail.list
          if(list){
            const len = list.length
            for (let i = 0;i < len;i++) {
              list[i].index = i + 1
              if(param.timeType == 'hour'){
                list[i].start = moment(list[i].start).format('YYYY-MM-DD HH:00')
              }
              list[i].leakPercent = list[i].leakPercent + '%'
            }
            const tHeader = ['序号', '监测点', '监测管井', '时间']
            tHeader.push(this.getZhibiaoTitle(param.zhibiao))
            const filterVal = ['index', 'mname', 'gname','time', 'value']
            const cols = [{wpx:40},{wpx:150},{wpx:150},{wpx:130},{wpx:80}]
            const data = this.formatJson(filterVal, list)
            export_json_to_excel(tHeader, data, '管网查询列表',cols)
          }
        })
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },
      handleDownload () {
        const param = Object.assign({}, this.queryParam)
        switch (param.timeType) {
          case 'hour':
            param.date = moment(param.date).format('YYYY-MM-DD')
            break
          case 'day':
            param.date = moment(param.date).format('YYYY-MM-DD')
            break
          case 'month':
            break
        }
        pipeQueryExprot(param).then(data => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '漏损查询-' + moment().format('YYYY-MM-DD') + '.xlsx')
          link.click()
        }).catch(err => {
          console.log(err)
        })
      },
      handleTableChange (pagination, filters, sorter) {
        console.log('Various parameters', pagination, filters, sorter)
        this.queryParam.pageNum = pagination.current
        this.queryParam.pageSize = pagination.pageSize
        this.fetchList()
      },
      onCascaderChange(value){
        console.log('onCascaderChange',value)
        const wfid = value[value.length - 1]
        this.queryParam.wfid = wfid
      },
      getZhibiaoTitle(zhibiao){
        for (let i = 0; i < this.zhibiaos.length; i++) {
          if(zhibiao === this.zhibiaos[i].id){
            return this.zhibiaos[i].title
          }
        }
        return ''
      },
      getChartOption1(xAxisData,series) {
        var option;
        let arr = []
        for (let i = 0; i < series.length; i++) {
          arr.push(series[i].name)
        }
        option = {
          grid: {
            top: 35,
            bottom: 40,
            left: 50,
            right: 35,
          },
          legend:{
            left: "right",
            data: arr
          },
          tooltip: {
            trigger: "axis",
            // formatter: function (params) {
            //   console.log('params',params)
            //
            //   return ''
            // }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxisData
          },
          yAxis: {
            type: "value",
            name: this.getZhibiaoTitle(this.queryParam.zhibiao)
          },
          // dataZoom: [{
          //         show: true,
          //         realtime: true,
          //         start: 30,
          //         end: 70,
          //         xAxisIndex: [0, 1]
          //     },
          //     {
          //         type: 'inside',
          //         realtime: true,
          //         start: 30,
          //         end: 70,
          //         xAxisIndex: [0, 1]
          //     }
          // ],
          color:[ //自定义的颜色
            'rgb(19,194,194)',
            'rgb(109,155,250)',
            'rgb(78,203,115)',
            'rgb(251,212,55)',
            'rgb(242,99,123)',
            'rgb(151,95,229)',
            'rgb(82,84,207)',
            'rgb(67,81,136)',
            'rgb(234,166,116)',
          ],
          series: series,
          dataZoom: [{
            type: 'slider',
            show: true,
            startValue: 0,
            endValue: 1000,
            height: '15',
            bottom: '0',
            zoomLock: false
          }]
        };
        return option
      },
      getChartOption2(xAxisData,seriesData) {
        var option;
        let arr = []
        // for (let i = 0; i < series.length; i++) {
        //   arr.push(series[i].name)
        // }
        option = {
          grid: {
            top: 35,
            bottom: 40,
            left: 50,
            right: 35,
          },
          legend:{
            left: "right",
            data: arr
          },
          tooltip: {
            trigger: "axis",
            // formatter: function (params) {
            //   console.log('params',params)
            //
            //   return ''
            // }
          },
          xAxis: {
            type: "category",
            data: xAxisData,
            axisLabel:{
              interval:0
            }
          },
          yAxis: {
            type: "value",
            name: this.getZhibiaoTitle(this.queryParam.zhibiao)
          },
          // dataZoom: [{
          //         show: true,
          //         realtime: true,
          //         start: 30,
          //         end: 70,
          //         xAxisIndex: [0, 1]
          //     },
          //     {
          //         type: 'inside',
          //         realtime: true,
          //         start: 30,
          //         end: 70,
          //         xAxisIndex: [0, 1]
          //     }
          // ],
          color:[ //自定义的颜色
            'rgb(19,194,194)',
            'rgb(109,155,250)',
            'rgb(78,203,115)',
            'rgb(251,212,55)',
            'rgb(242,99,123)',
            'rgb(151,95,229)',
            'rgb(82,84,207)',
            'rgb(67,81,136)',
            'rgb(234,166,116)',
          ],
          series: [{
            data: seriesData,
            type: 'bar',
            barMaxWidth: 80,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            itemStyle: {
              normal: {
                label: {
                  formatter: "{c}" + "m³",
                  show: true,
                  position: "top",
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: "12",
                    color: "rgb(19,194,194)"
                  }
                },
              }
            }
          }]
        };
        return option
      },
      handleQuery () {
        this.queryParam.pageNum = 1
        this.fetchList()
      },
      resetQuery () {
        this.fetchList()
      },
    }
  };
</script>

<style scoped>
    .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
